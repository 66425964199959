import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import './CareerPeopleCultureEnvironment.scss';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './style';
import clsx from 'clsx';
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';

const CareerPeopleCultureEnvironmnet = ({
  header,
  subHeader,
  content,
  hyperLinkLabel,
  doc,
  contentSecHeaderDesImg,
  contentSecHeaderDesc,
  contentMultiImg,
  dotBgImg,
  basePath,
}) => {
  const classes = useStyles(dotBgImg ? dotBgImg : '');
  return (
    <>
      <Container maxWidth="xl" className="people-culture-environment">
        <Grid container xl={12} lg={12} md={12} sm={12} xs={12} className="first-row">
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <h2
              className="heading"
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              locId="peopleCultureEnvironmentSectionHeader"
            >
              {header}
            </h2>
            <h3 className="sub-title" locId="peopleCultureEnvironmentSectionSubHeader">
              {subHeader}
            </h3>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="col-wrap">
            {content.map((con, index) => (
              <Grid
                item
                xl={4}
                lg={4}
                md={6}
                sm={12}
                xs={12}
                className={`desp${index + 1}`}
                locId="headerAndDescriptionSection"
                key={con?.headerText}
              >
                <h4 className="sec-title">{con?.headerText}</h4>
                {con?.description?.raw &&
                  documentToReactComponents(JSON.parse(con?.description?.raw))}
              </Grid>
            ))}
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="view-link">
              {(doc || hyperLinkLabel) &&
                ((doc?.link?.pdf?.file?.url || doc?.link?.url) ? (
                  <Link
                    to={doc?.link?.pdfOrUrl ? doc?.link?.pdf?.file?.url : doc?.link?.url}
                    target="_blank"
                    download
                    locId="hyperLinkLabel"
                  >
                    {hyperLinkLabel}
                  </Link>
                ) : (
                  <Link
                    to={doc?.link?.slug ? `/${basePath}/${doc?.link?.slug}` : '#'}
                    target="_blank"
                    download
                    locId="hyperLinkLabel"
                  >
                    {hyperLinkLabel}
                  </Link>
                ))}
            </Grid>
          </Grid>
        </Grid>


        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <h4 className="sec-title sec-title2" locId="contentSectionHeader">
            {contentSecHeaderDesImg?.name}
          </h4>
        </Grid>
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="left-col">
          <Grid item xl={6} lg={7} md={9} sm={12} xs={12}>
            <div className="desp" locId="contentSectionDescription">
              {contentSecHeaderDesImg?.description?.raw &&
                documentToReactComponents(JSON.parse(contentSecHeaderDesImg?.description?.raw))}
            </div>
          </Grid>
          <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
            <div className={clsx('img-box', classes['img-box-bg-img'])} locId="contentSectionImage">
              <img
                src={contentSecHeaderDesImg?.image?.file.url + '?fm=webp&q=100'}
                alt={contentSecHeaderDesImg?.image?.description}
              />
            </div>
          </Grid>
        </Grid>


        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="right-col">
          
        <Grid item xl={5} lg={6} md={12} sm={12} xs={12} className="rgt-content">
            <div className='rgt-header-content'>
              <h3 className="sec-title" locId="contentSectionHeaderDescription">
                {contentSecHeaderDesc?.headerText}
              </h3>
            </div>
            <div className="desp">
              {contentSecHeaderDesc?.description?.raw &&
                documentToReactComponents(JSON.parse(contentSecHeaderDesc?.description?.raw))}
            </div>
          </Grid>
          
          <Grid item xl={7} lg={6} md={12} sm={12} xs={12}>
            <div className="image-sec" locId="contentSectionMultipleImages">
              {contentMultiImg.map((i, index) => (
                <img src={i.file.url + '?fm=webp&q=100'}
                  alt={i?.description}
                  className={`thumb-img${index + 1}`} />
              ))}
            </div>
          </Grid>
          
        </Grid>
      </Container>
    </>
  );
};

export default CareerPeopleCultureEnvironmnet;
