import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Grid from '@material-ui/core/Grid';
import './CareerOurWounderfulBenefits.scss';
import clsx from 'clsx';
import { myStyles } from './styles';
import Container from '@material-ui/core/Container';

const CareerWounderfulBenefits = ({
  highlightsSectionShortDescription,
  capsuleImage,
  benefitsSectionHeader,
  benefitsSectionSubHeader,
  benefitsContentSection,
  benefitsContentHeaderShortDescription,
  dotMatrixBackgroundImage,
  benefitsSectionArrowImage,
  backgroundImg
}) => {
  const imageOptimum = '?fm=webp&q=100';
  const hightlightSection = highlightsSectionShortDescription?.raw;
  const image = capsuleImage?.file?.url;
  const leftImage = benefitsContentSection[0].image.file.url + imageOptimum;
  const rightImage = benefitsContentSection[1].image.file.url + imageOptimum;
  const description = capsuleImage?.description;
  const imageData = { dotMatrixBackgroundImage, backgroundImg };
  const classesdotmatrix = myStyles(imageData);

  const richtextOptions = {
    renderText: (text) => {
      return text.split('\\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };
  return (
    <>

      <Container maxWidth="xl" className='wonderful-benefits-cls'>
        <Grid className='txt-with-container'>
          <Grid container className='title-with-icon'>
            <Grid item xs={8} sm={8} md={5} lg={4} xl={4} className='title-allign'>
              <img src={benefitsSectionArrowImage?.file.url + imageOptimum} alt={benefitsSectionArrowImage?.description} className="img-allign" locId="benefitsSectionArrowImage" />
              <h2 className='title1' locId="benefitsSectionHeader">{benefitsSectionHeader}</h2>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='subtitles-allign'>
              <h3 className='sub-title' locId="benefitsSectionSubHeader">{benefitsSectionSubHeader}</h3>
              <h4 className='sec-title' locId="benefitsContentSection">{benefitsContentSection[0].name}</h4>
            </Grid>
          </Grid>
          <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='left-col'>
            <Grid item xl={6} lg={7} md={9} sm={12} xs={12}>
              <div className='desp' locId="benefitsContentSection">{documentToReactComponents(JSON.parse(benefitsContentSection[0].description.raw), richtextOptions)}</div>
            </Grid>
            <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
              <div className={clsx('image-box', classesdotmatrix['bg-dot-matrix'])} >
                <img src={leftImage} alt={benefitsContentSection[0].image.description} locId="benefitsContentSection" />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container className='health-wellness-cls'>
            <Grid item xl={6} lg={7} md={12} sm={12} xs={12} className="health-wellness-cls-right">
              <div className='right-header-text'>
                <h3 className='sec-title' locId="benefitsContentSection">{benefitsContentSection[1].name}</h3>
              </div>
              <div className='right-text-sec'>
                <div className='desp' locId="benefitsContentSection">{documentToReactComponents(JSON.parse(benefitsContentSection[1].description.raw), richtextOptions)}</div>
              </div>
            </Grid>
            <Grid item xl={6} lg={5} md={12} sm={12} xs={12}>
              <div className={clsx('image-box', classesdotmatrix['bg-dot-matrix'])} >
                <img src={rightImage} alt={benefitsContentSection[1].image.description} locId="benefitsContentSection" />
              </div>
            </Grid>
        </Grid>
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className='health-wellness-sebsection-cls'>
          {benefitsContentHeaderShortDescription.map((content, index) => {
            return (
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className={`desp${index + 1}`}>
                <h3 className='sec-title'>{content.headerText}</h3>
                {documentToReactComponents(JSON.parse(content.description.raw), richtextOptions)}
              </Grid>
            )
          })}
        </Grid>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className="text-capsule-container"
        >
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className='text-capsule-container-bg'>
            <div className={clsx('bg-capsule', classesdotmatrix['bg-capsule'])}>
              <Grid item xs={12} sm={10} md={9} lg={8} xl={7} >
                <div className="sub-title-capsule" locId="highlightsSectionShortDescription">
                  {hightlightSection &&
                    documentToReactComponents(JSON.parse(hightlightSection), richtextOptions)}
                </div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CareerWounderfulBenefits;
