import React from 'react';
import CareerAchievement from '../CareerAchievement';
import CareerHeader from '../CareerHeader';
import CareerOurQuote from '../CareerOurQuote';
import CareerWounderfulBenefits from '../CareerOurWounderfulBenefits';
import CareerPeopleCultureEnvironmnet from '../CareerPeopleCultureEnvironment';
import JoinOurTeam from '../JoinOurTeam';
import { useStyles } from './style';
import './CareerLanding.scss';
import CareerOurWinning from '../CareerOurWinning';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';

const CareerLanding = ({ data, basePath,backgroundImg, autoRotateEnabled }) => {
  
  const classes = useStyles(data);
  return (
    <div className="careers-main-cls">
      <div className={clsx('bg-donuts-left', classes['bg-left-donut'])}>
        <CareerHeader data={data} />
        <CareerOurWinning
          backgroundImage={data?.ourWinningBehaviorsBackgroundImage}
          ctaLabel={data?.ourWinningBehaviorsButtonLabel}
          externalLinkUrl={data?.ourWinningBehaviorsUrl}
          longDescription={data?.winningBehaviorsLongDescription}
          titleImageShortDecription={data?.winningBehaviorsTitleShortDescriptionAndLogo}
          dotMatrixBackgroundImage={data?.dotMatrixBackgroundImage}
        />
        <div className={clsx('bg-donuts-right', classes['bg-right-donut'])}>
          <CareerPeopleCultureEnvironmnet
            header={data?.peopleCultureEnvironmentSectionHeader}
            subHeader={data?.peopleCultureEnvironmentSectionSubHeader}
            content={data?.headerAndDescriptionSection}
            contentSecHeaderDesImg={data?.contentSectionHeaderDescriptionWithImage}
            contentSecHeaderDesc={data?.contentSectionHeaderDescription}
            contentMultiImg={data?.contentSectionMultipleImages}
            dotBgImg={data?.dotMatrixBackgroundImage}
            hyperLinkLabel={data?.hyperLinkLabelAndDocument?.label}
            doc={data?.hyperLinkLabelAndDocument}
            basePath={basePath}
          />
          <CareerOurQuote
            data={data?.careersTestimonialsSection}
            backgroundImg={backgroundImg}
            autoRotateEnabled={data?.careersSectionAutoRotate}
          />
          <CareerWounderfulBenefits
            highlightsSectionShortDescription={data?.highlightsSectionShortDescription}
            capsuleImage={data?.capsulesBackgroundImage}
            benefitsSectionHeader={data?.benefitsSectionHeader}
            benefitsSectionSubHeader={data?.benefitsSectionSubHeader}
            benefitsContentSection={data?.benefitsContentSection}
            benefitsContentHeaderShortDescription={data?.benefitsContentHeaderShortDescription}
            dotMatrixBackgroundImage={data?.dotMatrixBackgroundImage}
            benefitsSectionArrowImage={data?.benefitsSectionArrowImage}
            backgroundImg={backgroundImg}
          />
          <CareerAchievement
            image={data?.awardsSection}
            backgroundImg={backgroundImg}
            autoRotateEnabled={data?.awardsSectionAutoRotate}
          />
        </div>

        <div className="career-joinus">
          <Container maxWidth="xl" className="no-gutter">
            <JoinOurTeam
              data={data?.careersJoinOurTeamSection}
              matrixImg={data?.smallDotMatrixImage}
              basePath={basePath}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

export default CareerLanding;
