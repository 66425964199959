import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
import CareerLanding from '../components/CareerLanding';
//import LoginPage from '../pages/login';

export default function CareersPage({location ,pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };

  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };


  return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        location={location}
      >
        <SEO
          data={data?.contentfulCareers?.seoEntry}
          siteurl={pageContext?.slug}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url+'?fm=webp&q=100'}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
        />
        <CareerLanding
          data={data?.contentfulCareers}
          basePath={pageContext.basePath}
          backgroundImg={data?.contentfulComponentBackgroundImages}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query CareersQuery($contentful_id: String, $locale: String) {
    contentfulComponentBackgroundImages {
      hoverLeftArrowImage {
        file {
          url
        }
      }
      hoverRightArrowImage {
        file {
          url
        }
      }
      leftArrowImage {
        file {
          url
        }
      }
      rightArrowImage {
        file {
          url
        }
      }
      rightCapsulesBackgroundImage {
        file {
          url
        }
      }
    }
    contentfulCareers(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      careersSectionAutoRotate
      awardsSectionAutoRotate
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          title
          file {
            url
          }
        }
      }
      headerDotMatrixBackgroundImage {
        file {
          url
        }
      }
      gridBridgeImage {
        file {
          url
        }
      }
      headerSectionTitle
      headerSectionDescription {
        raw
      }
      heroImage {
        description
        file {
          url
        }
      }
      headerBackgroundDonutImage {
        file {
          url
        }
      }
      headerText
      benefitsSectionHeader
      benefitsSectionSubHeader
      benefitsContentSection {
        name
        image {
          file {
            url
          }
          description
        }
        description {
          raw
        }
      }
      benefitsContentHeaderShortDescription {
        description {
          raw
        }
        headerText
      }
      benefitsSectionArrowImage {
        description
        file {
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      highlightsSectionShortDescription {
        raw
      }
      careersIntroSection {
        header
        subHeader
        shortDescription {
          raw
        }
        isVideo
        videoComponent {
          videoComponent {
            dateOfPublishing(formatString: "")
            videoType
            videoId
            videoTitle
          }
        }
        image {
          description
          file {
            url
          }
        }
      }
      careersIntroSectionDescription {
        raw
      }
      careersIntroSectionImage {
        description
        file {
          url
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      ourWinningBehaviorsBackgroundImage {
        title
        file {
          url
        }
      }
      winningBehaviorsTitleShortDescriptionAndLogo {
        name
        entryTitle
        description {
          raw
        }
        image {
          file {
            url
          }
          title
          description
        }
      }
      winningBehaviorsLongDescription {
        raw
      }
      ourWinningBehaviorsButtonLabel
      ourWinningBehaviorsUrl
      peopleCultureEnvironmentSectionHeader
      peopleCultureEnvironmentSectionSubHeader
      shortDescription
      headerAndDescriptionSection {
        headerText
        description {
          raw
        }
      }
      hyperLinkLabelAndDocument{
        label
        link {
          ... on ContentfulComponentPdfWithUrl {
            id
            pdfOrUrl
            pdf {
              file {
                url
              }
              description
            }
            url
          }
          ... on ContentfulInternalLink {
            slug
          }
        }
      }
      contentSectionHeaderDescriptionWithImage {
        name
        image {
          description
          file {
            url
          }
        }
        description {
          raw
        }
      }
      contentSectionHeaderDescription {
        headerText
        description {
          raw
        }
      }
      contentSectionMultipleImages {
        description
        file {
          url
        }
      }
      careersTestimonialsSection {
        authorImage {
          description
          file {
            url
          }
        }
        authorDescription {
          raw
        }
        authorDesignation
        secondaryImage {
          description
          file {
            url
          }
        }
        authorName
      }
      benefitsSectionArrowImage {
        file {
          url
        }
      }
      benefitsSectionHeader
      benefitsSectionSubHeader
      benefitsContentSection {
        name
        image {
          file {
            url
          }
        }
        description {
          raw
        }
      }
      benefitsContentHeaderShortDescription {
        headerText
        description {
          raw
        }
      }
      awardsSection {
        description
        file {
          url
        }
      }
      careersJoinOurTeamSection {
        header
        subHeader
        shortDescription {
          raw
        }

        additionalImage {
          description
          file {
            url
          }
        }
        mainImage {
          description
          file {
            url
          }
        }
        ctaButton {
          displayPrimaryCta
          labelForPrimaryCta
          primaryCtaShouldBeDisplayedAs
          primaryCtaShouldOpenInTheSameTab
          callToAction {
            ... on ContentfulWebLink {
              id
              url
              openInANewTab
            }
          }
        }
      }
      dotMatrixBackgroundImage {
        file {
          url
        }
      }
      capsulesBackgroundImage {
        description
        file {
          url
        }
      }
      rightDonutBackgroundImage {
        file {
          url
        }
      }
      sys {
        contentType {
          sys {
            id
            type
          }
        }
      }
      leftDonutBackgroundImage {
        file {
          url
        }
      }
      smallDotMatrixImage {
        file {
          url
        }
      }
    }
  }
`;
