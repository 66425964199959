import React from 'react';
import Grid from '@material-ui/core/Grid';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Typography from '@material-ui/core/Typography';
import './CareerHeader.scss';
import { myStyles } from './styles';
import HeaderComponent from '../HeaderComponent/Index';
import HeaderIntroComponent from '../HeaderIntroComponent/Index';
import clsx from 'clsx';
import { Paper } from '@material-ui/core';
import VideoTiles from '../VideoTiles';
import Container from '@material-ui/core/Container';
const CareerHeader = ({ data }) => {
  const classes = myStyles(data);
  const headerSectionTitle = data?.headerSectionTitle;
  const headerSectionDescription = data?.headerSectionDescription;
  const careersIntroSectionDescription = JSON.parse(data?.careersIntroSectionDescription.raw);
  const imageOptimum = '?fm=webp&q=100';
  const optionShortDesc = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="intro-sec-short-desc" locId="careersIntroSectionShortDescription">{children}</p>
      ),
    },
  };
  const optionsIntro = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="intro-sec-desc" locId="careersIntroSectionDescription">{children}</p>
      ),
    },
  };
  return (
    <div>
      <div className={clsx('landing-hero-img-div', classes['landing-hero-img-div'])}>
        <HeaderComponent
          heroImage={data?.heroImage}
          classes={classes}
          circularSectionTitle={headerSectionTitle}
          circularSectionDescription={headerSectionDescription}
        />
      </div>
      <Container maxWidth="xl" className='zero-padding-div'>
       <Grid
          container
          className={clsx('txt-with-video-container', classes['txt-with-video-container'])}>
          <HeaderIntroComponent
            storyhistorySection={data?.careersIntroSection}
            aboutUsStorySectionTopic={data?.headerText}
            aboutUsStorySectionDescription={data?.shortDescription}
            classes={classes}
          />
        </Grid>
      <Grid container className="career-image-container">
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <div className={clsx('img-box', classes['img-box'])}>
            <img
              src={data?.careersIntroSectionImage?.file?.url+imageOptimum}
              alt={data?.careersIntroSectionImage?.description}
              locId="careersIntroSectionImage"
              className="text-img"
            />
          </div>
         
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        {documentToReactComponents(careersIntroSectionDescription, optionsIntro)}
        </Grid>
      </Grid>
      </Container>
      
    </div>
  );
};

export default CareerHeader;
