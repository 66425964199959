import React, {useRef,useEffect, useState}  from "react";
import './CareerOurQuote.scss';
import {Grid} from '@material-ui/core';
import Carousel from 'react-elastic-carousel';
import Item from '../TestimonialItem/Item';
import Container from '@material-ui/core/Container';
import { myStyles } from './styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import { PauseCircleFilledOutlined } from "@material-ui/icons";
const CareerOurQuote = ({data,backgroundImg,autoRotateEnabled }) => {
  const classes = myStyles(backgroundImg);
  const [isHovering, setIsHovering] = useState(false);
  const [flag, setFlag] = useState(false);
  const [sliderAnimation, setSliderAnimation] = useState(true);
  // breakpoints
  const breakPoints = [
    { width: 1, itemsToShow: 1 , itemsToScroll: 1},
    { width: 599, itemsToShow: 1, itemsToScroll: 1},
    { width: 904, itemsToShow: 1 , itemsToScroll: 1},
    { width: 1239, itemsToShow: 1 , itemsToScroll: 1},
  ];
  const carouselRef = useRef(null);
  let resetTimeout;
  const carouselProps = {
    breakPoints:breakPoints,
  }
  if(autoRotateEnabled && sliderAnimation){
    carouselProps.ref=carouselRef
    carouselProps.infiniteLoop = true
    carouselProps.enableAutoPlay= true
    carouselProps.autoPlaySpeed=5000
    carouselProps.isRTL=false
    carouselProps.onNextEnd = ({ index }) => {
      if (data.length == index + data.length / carouselRef.current.getNumOfPages()) {
        setFlag(true);
      }
    };
  }
  else{
    carouselProps.infiniteLoop = false
  carouselProps.ref = carouselRef;
    carouselProps.enableAutoPlay= false
  }
  const buttonEl = useRef(null);
  const registerAriaLabel = ()=>{
    buttonEl?.current?.querySelectorAll(".rec-dot").forEach((role)=>{
      role.setAttribute('aria-label', 'pagination');  
    });
  }
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handlePlayBtn = () => {
    setSliderAnimation(true);
    if (flag) {
      carouselRef?.current?.goTo(0);
      setFlag(false);
    }
  };
  const handlePauseBtn = () => {
    setSliderAnimation(false);
  };
  useEffect(() => {
    registerAriaLabel();
    if(window) 
    {
      window.addEventListener("resize",()=>{registerAriaLabel()})
    }
  }, []);
  return (
    <Container maxWidth="xl" className='career-our-quote-div'>
    <div ref={buttonEl}>
      <Grid container>
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid
            item
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={clsx("carousel-main-career-cls", classes['carousel-main-career-cls'])} 
          >
              <>
          {autoRotateEnabled && (
        <>
          {sliderAnimation ? (
            <>
              {!isHovering && (
                <IconButton className="play-pause-icon">
                  <PauseCircleOutlineIcon
                    onMouseEnter={handleMouseOver}
                    style={{ cursor: "pointer", width: "58px", height: "58px" }}
                    
                  />
                </IconButton>
              )}
              {isHovering && (
                <IconButton className="play-pause-icon" onClick={handlePauseBtn}>
                  <PauseCircleFilledOutlined
                    style={{ cursor: "pointer", width: "58px", height: "58px" }}
                    onMouseLeave={handleMouseOut}
                    
                  />
                </IconButton>
              )}
            </>
          ) : (
            <>
              {!isHovering && (
                <IconButton className="play-pause-icon">
                  <PlayCircleOutlineIcon
                    onMouseEnter={handleMouseOver}
                    style={{ cursor: "pointer", width: "58px", height: "58px" }}
                    
                  />
                </IconButton>
              )}
              {isHovering && (
                <IconButton className="play-pause-icon" onClick={handlePlayBtn}>
                  <PlayCircleFilledWhiteIcon
                    style={{ cursor: "pointer", width: "58px", height: "58px" }}
                    onMouseLeave={handleMouseOut}
                    
                  />
                </IconButton>
              )}
            </>
          )}
          </>)}
          </>

            <Carousel {...carouselProps}>
              {data.map((item, i) => (
                <Item key={i} item={item} />
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Grid>
    </div>
    </Container>
  );
};

export default CareerOurQuote;
