import React, { useRef, useEffect, useState } from "react";
import './AwardsSlider.scss';
import { Grid, Card } from '@material-ui/core';
import Carousel from 'react-elastic-carousel';
import { myStyles } from './styles';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import { PauseCircleFilledOutlined } from "@material-ui/icons";
export default function AwardsSlider({ awardsSection,backgroundImg, autoRotateEnabled }) {
  const numberOfAwards = process.env.GATSBY_NUMBER_OF_PROUD_ASSOCIATION_AND_AWARDS;
  const AwardList = awardsSection ? [...awardsSection].splice(0, numberOfAwards) : [];
  const [isHovering, setIsHovering] = useState(false);
  const [flag, setFlag] = useState(false);
  const [sliderAnimation, setSliderAnimation] = useState(true);
  const classes = myStyles(backgroundImg);
  const [sliderButton, setSliderButton] = useState('')
  const breakPoints = [
    { width: 1, itemsToShow: 2, itemsToScroll: 1, itemPadding: [0, 10], showArrows: AwardList.length > 2, pagination: AwardList.length > 2 },
    { width: 200, itemsToShow: 3, itemsToScroll: 1, itemPadding: [0, 10], showArrows: AwardList.length > 3, pagination: AwardList.length > 3 },
    { width: 904, itemsToShow: 5, itemsToScroll: 1, itemPadding: [0, 10], showArrows: AwardList.length > 5, pagination: AwardList.length > 5 },
    { width: 1239, itemsToShow: 5, itemsToScroll: 1, showArrows: AwardList.length > 5, pagination: AwardList.length > 5 }
  ];
  const carouselRef = useRef(null);
  let resetTimeout;
  const carouselProps = {
    breakPoints:breakPoints,
    itemPadding:[0, 20],
    infinite: true,
  }
  if(autoRotateEnabled && sliderAnimation){
    carouselProps.ref=carouselRef
    carouselProps.infiniteLoop = true
    carouselProps.enableAutoPlay= true
    carouselProps.autoPlaySpeed=5000
    carouselProps.isRTL=false
    carouselProps.onNextEnd = ({ index }) => {
      if (AwardList.length == index + AwardList.length / carouselRef.current.getNumOfPages()) {
        setFlag(true);
      }
    };
  }
  else{
    carouselProps.infiniteLoop = false
  carouselProps.ref = carouselRef;
    carouselProps.enableAutoPlay= false
  }
  const buttonEl = useRef(null);
  const registerAriaLabel = () => {
    setSliderButton(buttonEl?.current?.querySelectorAll(".rec-arrow"));
    buttonEl?.current?.querySelectorAll(".rec-dot").forEach((role) => {
      role.setAttribute('aria-label', 'pagination');
    });
  }
  
  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const handlePlayBtn = () => {
    setSliderAnimation(true);
    if (flag) {
      carouselRef?.current?.goTo(0);
      setFlag(false);
    }
  };
  const handlePauseBtn = () => {
    setSliderAnimation(false);
  };
  useEffect(() => {
    registerAriaLabel();
    if (window) {
      window.addEventListener("resize", () => { registerAriaLabel() })
    }
  }, []);
 
  return (
    <Grid item xs={12} ref={buttonEl}>
        <Grid item xs={12} className={clsx("image_slider",classes['image_slider'])}>
            <>
            {sliderButton?.length>0 && (
              <>
            {autoRotateEnabled && (
        <>
          {sliderAnimation ? (
              <>
                {!isHovering && (
                  <IconButton className="play-pause-icon">
                    <PauseCircleOutlineIcon
                      onMouseEnter={handleMouseOver}
                      style={{ cursor: "pointer", width: "58px", height: "58px" }}
                      
                    />
                  </IconButton>
                )}
                {isHovering && (
                  <IconButton className="play-pause-icon" onClick={handlePauseBtn}>
                    <PauseCircleFilledOutlined
                      style={{ cursor: "pointer", width: "58px", height: "58px" }}
                      onMouseLeave={handleMouseOut}
                      
                    />
                  </IconButton>
                )}
              </>
            ) : (
              <>
                {!isHovering && (
                  <IconButton className="play-pause-icon">
                    <PlayCircleOutlineIcon
                      onMouseEnter={handleMouseOver}
                      style={{ cursor: "pointer", width: "58px", height: "58px" }}
                      
                    />
                  </IconButton>
                )}
                {isHovering && (
                  <IconButton className="play-pause-icon" onClick={handlePlayBtn}>
                    <PlayCircleFilledWhiteIcon
                      style={{ cursor: "pointer", width: "58px", height: "58px" }}
                      onMouseLeave={handleMouseOut}
                      
                    />
                  </IconButton>
                )}
              </>
            )}
            </>
            )}
            </>
          )}
            
            
          <Carousel {...carouselProps}>
            
            {
              AwardList.map((item, i) => <Item key={i} item={item} />)
            }
          </Carousel>
          </> 
      </Grid>
    </Grid>
  )
}


function Item({ item }) {
  return (
    <>
      <Card className="root" >
        <Grid container item xl={12} lg={12} md={12} sm={12} xs={12} className="our-img-slider-bg" direction="column" >
          <div className="Ourslide_img">
            <img src={item?.file?.url+'?fm=webp&q=100'} alt={item?.description} locId={item?.description} />
          </div>
        </Grid>
      </Card>
    </>
  )
}
