import React from 'react';
import Grid from '@material-ui/core/Grid';
import './CareerAchievement.scss';
import Container from '@material-ui/core/Container';
import AwardsSlider from '../AwardsSlider';

const CareerAchievement = ({ image, backgroundImg, autoRotateEnabled }) => {
  return (
    <Container maxWidth="xl" className='careers-achievement-container'>
      <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className="our-career-cls">
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <AwardsSlider awardsSection={image} backgroundImg={backgroundImg} autoRotateEnabled={autoRotateEnabled}/>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CareerAchievement;
