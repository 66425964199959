import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
    'bg-dot-matrix': (imageData) => ({
        '&:after': {
            backgroundImage: `url(${imageData?.dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100)`,
        }
    }),
    'bg-capsule': (imageData) => ({
        backgroundImage: `url(${imageData?.backgroundImg?.rightCapsulesBackgroundImage?.file?.url}?fm=webp&q=100)`,
    }),
}));